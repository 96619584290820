import { useLayoutEffect, useRef } from 'react'
import { Loader } from 'shared/components/loader'
import SecondaryBreadcrubmsItem from './secondary-breadcrumbs-item'
import StyledLinkWithoutPrefetch from './styled-link-with-prefetched'
import { BreadcrumbsProps } from './types'

const COMMON_ITEMS_STYLES = 'leading-8 text-[18px] md:text-[20px] lg:text-[22px] md:truncate '

const Breadcrumbs = ({ data, isFetching }: BreadcrumbsProps) => {
  const listRef = useRef<HTMLUListElement>(null)
  const lastRef = useRef<HTMLLIElement>(null)

  useLayoutEffect(() => {
    if (listRef.current && lastRef.current) listRef.current.scrollTo(lastRef.current.offsetLeft, 0)
  }, [isFetching])

  return (
    <ul
      ref={listRef}
      className={`
        whitespace-nowrap md:truncate
        flex gap-3 items-center flex-nowrap md:flex-wrap lg:flex-nowrap gap-y-0
        overflow-scroll md:overflow:hidden
        min-h-[42px] w-full 
        hide-scrollbar
      `}
    >
      {data.map((el, idx) => {
        const isItemLast = idx === data.length - 1
        if (idx === 0) {
          return (
            <li key={idx}>
              {!el.isStatic && isFetching ? (
                <Loader small />
              ) : el.href ? (
                <StyledLinkWithoutPrefetch href={el.href}>
                  <h1
                    className={`group-focus-visible:text-blue font-semibold ${COMMON_ITEMS_STYLES}`}
                  >
                    {el.caption}
                  </h1>
                </StyledLinkWithoutPrefetch>
              ) : (
                <h1 className={`leading-8 font-semibold ${COMMON_ITEMS_STYLES}`}>{el.caption}</h1>
              )}
            </li>
          )
        } else {
          return (
            <li
              className={'flex items-center gap-3 flex-nowrap md:truncate'}
              key={idx}
              ref={isItemLast ? lastRef : undefined}
            >
              <div>
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="#253661"
                  xmlns="http://www.w3.org/2000/svg"
                  className={'fill-darkblue'}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.399746 13.2441C-0.132884 12.7113 -0.132884 11.8477 0.399746 11.3149L4.89268 6.82194L0.399746 2.32901C-0.133224 1.79633 -0.133224 0.932452 0.399596 0.399622C0.932426 -0.133207 1.79631 -0.133207 2.32913 0.399622L7.78654 5.85732C8.31917 6.39011 8.31917 7.25377 7.78654 7.78656L2.32899 13.2441C1.7962 13.7767 0.932536 13.7767 0.399746 13.2441Z"
                  />
                </svg>
              </div>

              {!el.isStatic && isFetching ? (
                <Loader small type="dark" />
              ) : el.href ? (
                <StyledLinkWithoutPrefetch href={el.href}>
                  <SecondaryBreadcrubmsItem
                    isLast={isItemLast}
                    className={`group-focus-visible:text-blue ${COMMON_ITEMS_STYLES}`}
                  >
                    {el.caption}
                  </SecondaryBreadcrubmsItem>
                </StyledLinkWithoutPrefetch>
              ) : (
                <SecondaryBreadcrubmsItem isLast={isItemLast} className={COMMON_ITEMS_STYLES}>
                  {el.caption}
                </SecondaryBreadcrubmsItem>
              )}
            </li>
          )
        }
      })}
    </ul>
  )
}

export default Breadcrumbs

export * from './types'
