interface SecondaryBreadcrubmsItemProps
  extends React.PropsWithChildren,
    Partial<Omit<HTMLElement, 'children'>> {
  isLast: boolean
}

export default function SecondaryBreadcrubmsItem({
  children,
  isLast,
  className,
}: SecondaryBreadcrubmsItemProps) {
  return <h2 className={`${isLast ? 'text-darkblue' : ''} ${className}`}>{children}</h2>
}
