import SettingsMenuSkeleton from 'modules/profile/components/settings-menu-skeleton'
import { customerSettingsMenuHref } from 'modules/profile/constants/settings-menu-href'
import { useSettingsMenuItems } from 'modules/profile/hooks/use-settings-menu-items'
import { useRouter } from 'next/router'
import React, { useLayoutEffect, useMemo } from 'react'
import Breadcrumbs, { BreadcrumbsData } from 'shared/components/breadcrumbs'
import { PrimaryButton } from 'shared/components/button'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import SaveIcon from 'shared/icons/save-icon'
import { twMerge } from 'tailwind-merge'
import { isValidMenuPath } from '../utils/menu-path-validators'

interface SettingsLayoutProps {
  isFetching?: boolean
  onSave?: () => void
  disabled?: boolean
  needSave?: boolean
  customHeader?: JSX.Element
  titles: BreadcrumbsData[]
  hideSettingsMenu?: boolean
}

const SettingsLayout = ({
  children,
  isFetching,
  onSave,
  disabled,
  needSave = true,
  customHeader,
  titles,
  hideSettingsMenu,
}: React.PropsWithChildren<SettingsLayoutProps>) => {
  const { t } = useLocoTranslation()
  const { user, isLoading } = useUser()
  const router = useRouter()

  const breadCrumbsData: BreadcrumbsData[] = useMemo(() => {
    return ([{ isStatic: true, caption: t('global.settings') }] as BreadcrumbsData[]).concat(titles)
  }, [titles, t])

  const settingsMenuItems = useSettingsMenuItems()

  useLayoutEffect(() => {
    if (settingsMenuItems.length && !isValidMenuPath(settingsMenuItems, router.pathname)) {
      router.replace(settingsMenuItems[0].href)
    }
  }, [settingsMenuItems, router])

  return (
    <>
      <div className="flex mb-5 justify-between">
        <Breadcrumbs data={breadCrumbsData} isFetching={false} />
        <div className={'flex gap-3'}>
          {customHeader}
          {needSave && (
            <PrimaryButton
              isFetching={isFetching}
              disabled={!user || disabled}
              onClick={onSave}
              className="flex justify-between items-center"
            >
              <SaveIcon />
              <span className="hidden sm:flex"> {t('global.save')}</span>
            </PrimaryButton>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-3 lg:flex-row lg:gap-10 mb-9">
        {!hideSettingsMenu && (
          <div className="relative w-full lg:w-[250px] lg:min-h-[506px] ">
            {isLoading ? (
              <SettingsMenuSkeleton />
            ) : (
              <ul
                className={`h-fit max-h-[320px] lg:h-max lg:max-h-full w-full inset-0 flex flex-col text-base rounded-md lg:w-[250px] bg-white overflow-auto last:border-b-transparent`}
              >
                {settingsMenuItems
                  // A temporary filter for hiding zoom page from navbar
                  .filter(setting => setting.href !== customerSettingsMenuHref.zoom_integration)
                  .map(setting => (
                    <li key={setting.href} className={'text-[14px]'}>
                      <LinkWithoutPrefetch
                        className={twMerge(
                          `main-transition-colors flex px-5 py-2.5 border-b border-b-gray/10 hover:bg-blue-100 focus:outline-none focus-visible:border-b-blue focus-visible:bg-blue-100`,
                          setting.validate(router.pathname)
                            ? 'text-blue font-bold bg-blue-100 border-l-4 border-l-blue'
                            : 'text-gray-300 border-l-4 border-l-transparent',
                        )}
                        href={setting.href}
                      >
                        {t(setting.name)}
                      </LinkWithoutPrefetch>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        )}
        {children}
      </div>
    </>
  )
}

export default SettingsLayout
