import { settingsMenuWildcards } from '../constants/settings-menu-href'
import { SettingsMenuEnum } from '../enums/settings-menu-enum'
import { SettingsMenuInterface } from '../types/settings-interface'

export const isValidMenuPath = (settingsMenuItems: SettingsMenuInterface, pathname: string) =>
  settingsMenuItems.some(({ validate }) => validate(pathname))

const isMatchingMenuItem = (
  href: string,
  settingsMenuEnum: SettingsMenuEnum,
  pathname: string,
): boolean => {
  if (href === pathname) return true

  const wildcard = settingsMenuWildcards[settingsMenuEnum]

  return !!wildcard && new RegExp(wildcard).test(pathname)
}

export const isMatchingMenuItemCurried =
  (href: string, settingsMenuEnum: SettingsMenuEnum) => (pathname: string) =>
    isMatchingMenuItem(href, settingsMenuEnum, pathname)
