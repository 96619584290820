export enum SettingsMenuEnum {
  profile = 'profile',
  account = 'account',
  my_plan = 'my_plan',
  shipping = 'shipping',
  payment_gateways = 'payment_gateways',
  manage_subscriptions = 'manage_subscriptions',
  mailing = 'mailing',
  membership = 'membership',
  payment_page = 'payment_page',
  sales_funnel = 'sales_funnel',
  affiliate_program = 'affiliate_program',
  custom_domains = 'custom_domains',
  public_api = 'public_api',
  active_campaign = 'active_campaign',
  affiliate_commissions = 'affiliate_commissions',
  notification = 'notification',
  workspace_members = 'workspace_members',
  change_password = 'change_password',
  webhooks = 'webhooks',
  google_integration = 'google_integration',
  zoom_integration = 'zoom_integration',
}
