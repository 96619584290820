import { ComponentProps } from 'react'
import LinkWithoutPrefetch from '../link-without-prefetch'

export default function StyledLinkWithoutPrefetch({
  children,
  ...props
}: ComponentProps<typeof LinkWithoutPrefetch>) {
  return (
    <LinkWithoutPrefetch className={`outline-none group`} {...props}>
      {children}
    </LinkWithoutPrefetch>
  )
}
