import React from 'react'

const SettingsMenuSkeleton = () => (
  <div
    className={`h-fit w-full inset-0 flex flex-col animate-pulse rounded-md lg:w-[250px] bg-white overflow-auto last:border-b-transparent`}
  >
    <div className="px-5 py-2.5 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5">.</div>
    </div>
    <div className="px-5 py-2.5 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5">.</div>
    </div>
    <div className="px-5 py-2.5 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5">.</div>
    </div>
    <div className="px-5 py-2.5 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5">.</div>
    </div>
    <div className="px-5 py-2.5 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5">.</div>
    </div>
  </div>
)

export default SettingsMenuSkeleton
