import React from 'react'

function SaveIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5606 3.06065L10.9393 0.43934C10.658 0.15804 10.2765 0 9.87869 0H1.5C0.67156 0 0 0.67156 0 1.5V12.5C0 13.3284 0.67156 14 1.5 14H12.5C13.3284 14 14 13.3284 14 12.5V4.12132C14 3.7235 13.842 3.34197 13.5607 3.06067L13.5606 3.06065ZM7 12C5.89544 12 5 11.1046 5 9.99999C5 8.89543 5.89544 7.99999 7 7.99999C8.10456 7.99999 9 8.89543 9 9.99999C9 11.1046 8.10456 12 7 12ZM10 2.48374V5.62499C10 5.83208 9.83209 5.99999 9.625 5.99999H2.375C2.16791 5.99999 2 5.83208 2 5.62499V2.37499C2 2.1679 2.16791 1.99999 2.375 1.99999H9.51625C9.61572 1.99999 9.71109 2.03949 9.78141 2.10983L9.89016 2.21858C9.9605 2.28889 10 2.38428 10 2.48374Z"
      />
    </svg>
  )
}

export default SaveIcon
