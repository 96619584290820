import { useShowChangePasswordPage } from 'shared/hooks/use-show-change-password-page'
import useUser from 'shared/hooks/use-user'
import { SettingsMenuEnum } from '../enums/settings-menu-enum'

export const useSettingsMenuItemsFilter = () => {
  const { isLoading: isUserLoading } = useUser()
  const { showChangePasswordPage } = useShowChangePasswordPage()

  const shouldDisplayMenuItem = (item: SettingsMenuEnum) => {
    if (isUserLoading) return true

    switch (item) {
      case SettingsMenuEnum.change_password: {
        return showChangePasswordPage
      }
      default: {
        return true
      }
    }
  }

  const filterMenuItems = (items: SettingsMenuEnum[]) => {
    return items.filter(shouldDisplayMenuItem)
  }

  return {
    filterMenuItems,
  }
}
